import event1 from "static/images/events/1.jpg";
import event2 from "static/images/events/2.jpg";
import event3 from "static/images/events/3.jpg";
import event4 from "static/images/events/4.jpg";
import event5 from "static/images/events/5.jpg";
import event6 from "static/images/events/6.jpg";
import event7 from "static/images/events/7.jpg";
import event8 from "static/images/events/8.jpg";
import event9 from "static/images/events/9.jpg";
import event10 from "static/images/events/10.jpg";
import event26 from "static/images/events/26.jpg";
import event27 from "static/images/events/27.jpg";
import event28 from "static/images/events/28.jpg";
import event29 from "static/images/events/29.jpg";
import event30 from "static/images/events/30.jpg";
import event31 from "static/images/events/31.jpg";
import event32 from "static/images/events/32.jpg";
import event33 from "static/images/events/33.jpg";
import event34 from "static/images/events/34.jpg";
import event35 from "static/images/events/35.jpg";
import event36 from "static/images/events/36.jpg";
import event37 from "static/images/events/37.jpg";
import event38 from "static/images/events/38.jpg";
import event39 from "static/images/events/39.jpg";
import event40 from "static/images/events/40.jpg";
import event41 from "static/images/events/41.jpg";
import event42 from "static/images/events/42.jpg";
import event43 from "static/images/events/43.jpg";
// 
import event44 from "static/images/events/44.jpg";
import event45 from "static/images/events/45.jpg";
import event46 from "static/images/events/46.jpg";
import event47 from "static/images/events/47.jpg";
import event48 from "static/images/events/48.jpg";
import event49 from "static/images/events/49.jpg";
import event50 from "static/images/events/50.jpg";
import event51 from "static/images/events/51.jpg";
import event52 from "static/images/events/52.jpg";
import event53 from "static/images/events/53.jpg";
import event54 from "static/images/events/54.jpg";
import event55 from "static/images/events/55.jpg";
import event56 from "static/images/events/56.jpg";
import event57 from "static/images/events/57.jpg";
import event58 from "static/images/events/58.jpg";
import event59 from "static/images/events/59.jpg";
import event60 from "static/images/events/60.jpg";
import event61 from "static/images/events/61.jpg";
import event62 from "static/images/events/62.jpg";
import event63 from "static/images/events/63.jpg";
//
import event64 from "static/images/events/64.jpg";
import event65 from "static/images/events/65.JPG";
import event66 from "static/images/events/66.JPG";
import event67 from "static/images/events/67.JPG";
import event68 from "static/images/events/68.JPG";
import event69 from "static/images/events/69.JPG";
import event70 from "static/images/events/70.JPG";
import event71 from "static/images/events/71.JPG";
import event72 from "static/images/events/72.JPG";
import event73 from "static/images/events/73.JPG";
import event74 from "static/images/events/74.jpg";
import event75 from "static/images/events/75.JPG";
import event76 from "static/images/events/76.JPG";
import event77 from "static/images/events/77.jpg";
import event78 from "static/images/events/78.jpg";
import event79 from "static/images/events/79.jpg";
import event80 from "static/images/events/80.jpg";
import event81 from "static/images/events/81.jpg";
import event82 from "static/images/events/82.JPG";
import event83 from "static/images/events/83.jpg";
import event84 from "static/images/events/84.jpg";
import event85 from "static/images/events/85.JPG";
import event86 from "static/images/events/86.jpg";
import event87 from "static/images/events/87.jpg";
import event88 from "static/images/events/88.JPG";
import event89 from "static/images/events/89.JPG";
import event90 from "static/images/events/90.jpg";
import event91 from "static/images/events/91.JPG";
import event92 from "static/images/events/92.jpg";
import event93 from "static/images/events/93.jpg";
import event94 from "static/images/events/94.JPG";
import event95 from "static/images/events/95.JPG";
import event96 from "static/images/events/96.JPG";
import event97 from "static/images/events/97.jpg";
import event98 from "static/images/events/98.jpg";
import event99 from "static/images/events/99.jpg";
import event100 from "static/images/events/100.JPG";
import event101 from "static/images/events/101.JPG";
import event102 from "static/images/events/102.jpg";
import event103 from "static/images/events/103.JPG";
import event104 from "static/images/events/104.JPG";
import event105 from "static/images/events/105.JPG";
import event106 from "static/images/events/106.JPG";
import event107 from "static/images/events/107.JPG";
import event108 from "static/images/events/108.JPG";
import event109 from "static/images/events/109.JPG";
import event110 from "static/images/events/110.JPG";
import event111 from "static/images/events/111.JPG";
import event112 from "static/images/events/112.JPG";
import event113 from "static/images/events/113.JPG";
import event114 from "static/images/events/114.JPG";
import event115 from "static/images/events/115.JPG";
import event116 from "static/images/events/116.JPG";
import event117 from "static/images/events/117.JPG";



export const tile1Data = [
  {
    id: 1,
    img: event44,
    title: "Sahodaya",
    cols: 2
  },
  {
    id: 2,
    img: event45,
    title: "Sahodaya",
    cols: 1
  },
  {
    id: 3,
    img: event46,
    title: "Sahodaya",
  },
  {
    id: 4,
    img: event47,
    title: "Sahodaya",
  },
  {
    id: 5,
    img: event48,
    title: "Sahodaya",
  },
  {
    id: 6,
    img: event49,
    title: "Sahodaya",
  },
  {
    id: 7,
    img: event50,
    title: "Sahodaya",
  },
  {
    id: 8,
    img: event51,
    title: "Sahodaya",
  },
  {
    id: 9,
    img: event52,
    title: "Sahodaya",
  },
  {
    id: 10,
    img: event53,
    title: "Sahodaya",
  },
  {
    id: 11,
    img: event54,
    title: "Sahodaya",
  },
  {
    id: 12,
    img: event55,
    title: "Sahodaya",
  },
  {
    id: 13,
    img: event56,
    title: "Sahodaya",
  },
  {
    id: 14,
    img: event57,
    title: "Sahodaya",
  },
  {
    id: 15,
    img: event58,
    title: "Sahodaya",
  },
  {
    id: 16,
    img: event59,
    title: "Sahodaya",
  },
  {
    id: 17,
    img: event60,
    title: "Sahodaya",
  },
  {
    id: 18,
    img: event61,
    title: "Sahodaya",
  },
  {
    id: 19,
    img: event62,
    title: "Sahodaya",
  },
  {
    id: 20,
    img: event63,
    title: "Sahodaya",
  },
]

export const tile2Data = [
  {
    id: 1,
    img: event1,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 2,
    img: event2,
    title: "Sahodaya",
    cols: 1,
  },
  {
    id: 3,
    img: event3,
    title: "Sahodaya",
  },
  {
    id: 4,
    img: event4,
    title: "Sahodaya",
  },
  {
    id: 5,
    img: event5,
    title: "Sahodaya",
  },
  {
    id: 6,
    img: event6,
    title: "Sahodaya",
  },
  {
    id: 7,
    img: event7,
    title: "Sahodaya",
  },
  {
    id: 8,
    img: event8,
    title: "Sahodaya",
  },
  {
    id: 9,
    img: event9,
    title: "Sahodaya",
  },
  {
    id: 10,
    img: event10,
    title: "Sahodaya",
  },
  {
    id: 26,
    img: event26,
    title: "Sahodaya",
  },
  {
    id: 27,
    img: event27,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 28,
    img: event28,
    title: "Sahodaya",
  },
  {
    id: 29,
    img: event29,
    title: "Sahodaya",
  },
  {
    id: 30,
    img: event30,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 31,
    img: event31,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 32,
    img: event32,
    title: "Sahodaya",
  },
  {
    id: 33,
    img: event33,
    title: "Sahodaya",
  },
  {
    id: 34,
    img: event34,
    title: "Sahodaya",
  },
  {
    id: 35,
    img: event35,
    title: "Sahodaya",
  },
  {
    id: 36,
    img: event36,
    title: "Sahodaya",
  },
  {
    id: 37,
    img: event37,
    title: "Sahodaya",
  },
  {
    id: 38,
    img: event38,
    title: "Sahodaya",
  },
  {
    id: 39,
    img: event39,
    title: "Sahodaya",
  },
  {
    id: 40,
    img: event40,
    title: "Sahodaya",
  },
  {
    id: 41,
    img: event41,
    title: "Sahodaya",
  },
  {
    id: 42,
    img: event42,
    title: "Sahodaya",
  },
  {
    id: 43,
    img: event43,
    title: "Sahodaya",
  },
];

export const tile3Data = [
  {
    id: 1,
    img: event64,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 2,
    img: event65,
    title: "Sahodaya",
    cols: 1,
  },
  {
    id: 3,
    img: event66,
    title: "Sahodaya",
  },
  {
    id: 4,
    img: event67,
    title: "Sahodaya",
  },
  {
    id: 5,
    img: event68,
    title: "Sahodaya",
  },
  {
    id: 6,
    img: event69,
    title: "Sahodaya",
  },
  {
    id: 7,
    img: event70,
    title: "Sahodaya",
  },
  {
    id: 8,
    img: event71,
    title: "Sahodaya",
  },
  {
    id: 9,
    img: event72,
    title: "Sahodaya",
  },
  {
    id: 10,
    img: event73,
    title: "Sahodaya",
  },
  {
    id: 26,
    img: event74,
    title: "Sahodaya",
  },
  {
    id: 27,
    img: event75,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 28,
    img: event76,
    title: "Sahodaya",
  },
  {
    id: 29,
    img: event77,
    title: "Sahodaya",
  },
  {
    id: 30,
    img: event78,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 31,
    img: event79,
    title: "Sahodaya",
    cols: 2,
  },
  {
    id: 32,
    img: event80,
    title: "Sahodaya",
  },
  {
    id: 33,
    img: event81,
    title: "Sahodaya",
  },
  {
    id: 34,
    img: event82,
    title: "Sahodaya",
  },
  {
    id: 35,
    img: event83,
    title: "Sahodaya",
  },
  {
    id: 36,
    img: event84,
    title: "Sahodaya",
  },
  {
    id: 37,
    img: event85,
    title: "Sahodaya",
  },
  {
    id: 38,
    img: event86,
    title: "Sahodaya",
  },
  {
    id: 39,
    img: event87,
    title: "Sahodaya",
  },
  {
    id: 40,
    img: event88,
    title: "Sahodaya",
  },
  {
    id: 41,
    img: event89,
    title: "Sahodaya",
  },
  {
    id: 42,
    img: event90,
    title: "Sahodaya",
  },
  {
    id: 43,
    img: event91,
    title: "Sahodaya",
  },
  {
    id: 44,
    img: event92,
    title: "Sahodaya",
  },
  {
    id: 45,
    img: event93,
    title: "Sahodaya",
  },
  {
    id: 46,
    img: event94,
    title: "Sahodaya",
  },
  {
    id: 47,
    img: event95,
    title: "Sahodaya",
  },
  {
    id: 48,
    img: event96,
    title: "Sahodaya",
  },
  {
    id: 49,
    img: event97,
    title: "Sahodaya",
  },
  {
    id: 50,
    img: event98,
    title: "Sahodaya",
  },
  {
    id: 51,
    img: event99,
    title: "Sahodaya",
  },
  {
    id: 52,
    img: event100,
    title: "Sahodaya",
  },
  {
    id: 53,
    img: event101,
    title: "Sahodaya",
  },
  {
    id: 54,
    img: event102,
    title: "Sahodaya",
  },
  {
    id: 55,
    img: event103,
    title: "Sahodaya",
  },
  {
    id: 56,
    img: event104,
    title: "Sahodaya",
  },
  {
    id: 57,
    img: event105,
    title: "Sahodaya",
  },
  {
    id: 58,
    img: event106,
    title: "Sahodaya",
  },
  {
    id: 59,
    img: event107,
    title: "Sahodaya",
  },
  {
    id: 60,
    img: event108,
    title: "Sahodaya",
  },
  {
    id: 61,
    img: event109,
    title: "Sahodaya",
  },
  {
    id: 62,
    img: event110,
    title: "Sahodaya",
  },
  {
    id: 63,
    img: event111,
    title: "Sahodaya",
  },
  {
    id: 64,
    img: event112,
    title: "Sahodaya",
  },
  {
    id: 65,
    img: event113,
    title: "Sahodaya",
  },
  {
    id: 66,
    img: event114,
    title: "Sahodaya",
  },
  {
    id: 67,
    img: event115,
    title: "Sahodaya",
  },
  {
    id: 68,
    img: event116,
    title: "Sahodaya",
  },
  {
    id: 69,
    img: event117,
    title: "Sahodaya",
  },
];
