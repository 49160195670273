import React from "react";
import { Row, Col, Image, Card } from "antd";

import placeholder from "static/images/office_bearers/placeholder.jpg";
import rajeev from "static/images/office_bearers/Rajeev Kumar President sahodaya.jpg";
import ranjana from "static/images/office_bearers/ranjana.jpeg";
import rashmi from "static/images/office_bearers/rashmi.jpg";
import raj from "static/images/office_bearers/raj_aloni.jpg";
import alok from "static/images/office_bearers/alok.jpg";
import nandita from "static/images/office_bearers/nandita_khanna.jpg";
import alokSharma from "static/images/office_bearers/alok_sharma.jpg";
import anilkumar from "static/images/office_bearers/Mr B. Anil Kumar Vice President Sahodaya.jpg";
import avneetkaur from "static/images/office_bearers/Mrs Avneet Kaur Member Sahodaya.jpg";
import jacquline from "static/images/office_bearers/Dr Jacquline Mahadik Member Sahodaya.jpg";
import laxmiarun from "static/images/office_bearers/Mrs Lakshmi Arun Joint Secretary Sahodaya.jpg";

const { Meta } = Card;

const people = [
  {
    id: 1,
    name: "MR. RAJEEV KUMAR GARG",
    img: rajeev,
    position:
      "Principal, Datta Meghe World Academy, Airoli & President, Sahodaya Schools Association, Mumbai & Metropolitan",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 2,
    name: "Mr B. Anil Kumar",
    img: anilkumar,
    position:
      "Principal, Chinmaya Vidyalaya, Boisar, Dist Palghar ; Vice President, Sahodaya Schools Association, Mumbai & Metropolitan Region",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 3,
    name: "MRS. RANJANA JANGRA",
    img: ranjana,
    position:
      "Principal, B K Birla School, Kalyan, Dist Thane and Vice- President, Sahodaya Schools Association, Mumbai & Metropolitan Region",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 4,
    name: "Mrs Avneet Kaur",
    img: avneetkaur,
    position:
      "Principal, New Bombay City School, Navi Mumbai; Member, Sahodaya Schools Association, Mumbai & Metropolitan Region",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 5,
    name: "Mr Alok Katdare",
    img: alok,
    position:
      "Principal, Reliance Foundation School, Koparkhairne, Navi Mumbai & Member, Sahodaya Schools Association",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 6,
    name: "Dr JacqulineRamesh Mahadik",
    img: jacquline,
    position: "Principal, Holyfaith English School, Ambernath East, Dist Thane; Member Sahodaya Schools Association, Mumbai & Metropolitan",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 7,
    name: "Mr Alok Sharma",
    img: alokSharma,
    position: "Principal Bhartiya Vidyapeeth English Medium School, CBD Belapur, Navi Mumbai & Treasurer, Sahodaya Schools Association",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 8,
    name: "Dr Rashmi Rekha Saha",
    img: rashmi,
    position:
      "Principal, Lodha World School, LSG Palava phase 2, Dombivali & Secretary, Sahodaya Schools Association.",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 9,
    name: "MRS. RAJ ALONI",
    img: raj,
    position:
      "Principal, Ramsheth Thakur Public School, Kharghar & Secretary, Sahodaya Schools Association",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 9,
    name: "Mrs Lakshmi Arun Panikeveetil",
    img: laxmiarun,
    position: "Principal, Yashwantrao Chavan English Medium School, Navi Mumbai; Joint Secretary, Sahodaya Schools Association, Mumbai & Metropolitan Region",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  {
    id: 9,
    name: "Mrs Nandita Khanna",
    img: nandita,
    position: "Principal, Nalanda Public School, Mulund (E) Mumbai & Joint Secretary, Sahodaya Schools Association",
    description:
      "Short Description or quote Short Description or quote Short Description or quote",
  },
  
];

const BearerCard = ({ img, name, position }) => {
  return (
    <Card
      className="person-card"
      hoverable
      cover={<Image src={img} alt={name} />}
    >
      <Meta title={name} description={position} />
    </Card>
  );
};

const OfficeBearers = () => {
  return (
    <div className="office-bearers-section">
      <h2 className="office-bearers-title">Office Bearers</h2>
      <Row gutter={12}>
        {people.map((person) => (
          <Col
            className="card-col"
            key={person.id}
            xs={24}
            sm={12}
            md={8}
            lg={6}
          >
            <BearerCard {...person} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default OfficeBearers;
