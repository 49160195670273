import React from "react";
import { NavLink } from "react-router-dom";

const Banner = () => {
    return (
        <a className="banner" href="https://bit.ly/LEADERSHIP_EXCELLENCE_CONCLAVE" target="_blank" rel="noopener noreferrer">
            Registration for Leadership Excellence Conclave 2024
            </a>
    );
};

Banner.displayName = "Banner";

export default Banner;